'use client';
import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRouter } from 'next/navigation';

type LinkProps = MuiLinkProps & {
  link: string;
  children: React.ReactNode | React.ReactNode[];
  herf?: string;
};

export const Link = ({ link, herf = link, children, ...props }: LinkProps) => {
  const router = useRouter();

  const StyledLink = styled(MuiLink)({
    color: 'inherit',
  });

  return (
    <StyledLink href={herf} underline="none" onClick={() => router.push(link)} {...props}>
      {children}
    </StyledLink>
  );
};
