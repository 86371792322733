'use client';

import { Alert, AlertColor, Box, Checkbox, Container, Grid, TextField, Typography } from '@mui/material';
import { useLoginAtreus } from '@wilxite-packages/wx-atreus';
import { Button } from '@ui/button';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import styles from './page.module.scss';
import Image from 'next/image';
import petersLogo from '../../../public/peters-logo.png';
import { Link } from '@ui/link';
import { zodResolver } from '@hookform/resolvers/zod';
import { logInSchema, logInSchemaType } from '@lib/types';
import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Header } from '@ui/header';

type ReqResponse = {
  message: string;
  status: AlertColor;
};

const Login = () => {
  const [response, setResponse] = useState<ReqResponse | undefined>(undefined);
  const { _ } = useLingui();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<logInSchemaType>({
    resolver: zodResolver(logInSchema),
  });

  const { loginRequest, loading } = useLoginAtreus((res) => {
    switch (res.status) {
      case 'success':
        setResponse({ status: 'success', message: _(msg`logInSuccess`) });
        window.location.href = '/';
        break;
      case 'failure':
        setResponse({ status: 'error', message: _(msg`logInFail`) });
        break;
      default:
        setResponse({ status: 'error', message: _(msg`unknownError`) });
        break;
    }
  });

  const login = async (data: logInSchemaType) => {
    setResponse(undefined);
    loginRequest(data);
  };

  return (
    <>
      <Header/>
      <main className={styles.main}>
        <div className={styles.body}>
          <div className={styles.logo}>
            <Image src={petersLogo} width={150} alt="Peters logo" />
          </div>
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              {response ? <Alert severity={response.status}>{response.message}</Alert> : null}
              {loading ? <Alert severity="info">{_(msg`loading`)}</Alert> : null}
              <Box component="form" onSubmit={handleSubmit(login)} noValidate sx={{ mt: 1 }}>
                {errors.email && (
                  <Typography variant="caption" color="error">
                    {errors.email.message}
                  </Typography>
                )}
                <TextField
                  {...register('email')}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={_(msg`emailAddress`)}
                  name="email"
                  autoComplete="email"
                  type='email'
                />
                {errors.password && (
                  <Typography variant="caption" color="error">{`${errors.password.message}`}</Typography>
                )}
                <TextField
                  {...register('password')}
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={_(msg`password`)}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Checkbox defaultChecked {...register('stay_logged_in')} />
                <Typography variant="caption">{_(msg`stayLoggedInQuestion`)}</Typography>

                <Button
                  type="submit"
                  label={_(msg`signIn`)}
                  size="large"
                  disabled={isSubmitting || loading}
                  fullWidth
                  variant="contained"
                  style={{textTransform:"initial"}}
                  sx={{ mt: 3, mb: 2 }}
                />

                <Grid container>
                  <Grid item xs sx={{ textAlign: 'right' }}>
                    <Link link="https://pharos.peters.co.uk/recover/start" variant="body2">
                      {_(msg`forgotPasswordQuestion`)}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </main>
    </>
  );
};

export default Login;
